.MuiFormControl-root-MuiTextField-root {
    width: calc(100% - 98px);
    justify-content: center;
    display: flex !important;
    margin: 0 auto !important;
    background-color: #ba0c2f !important;
    margin-bottom: 16px !important;
    max-height: 35px;
    border-radius: 23px;
    opacity: 1 !important;
  }

  @media (max-width: 900px) {
    .MuiFormControl-root-MuiTextField-root {
      width: calc(100% - 50px);
    }
  }

  .MuiFormControl-root-MuiTextField-root input,
  .MuiFormControl-root-MuiTextField-root input::placeholder {
    color: #ffffff !important;
    opacity: 1 !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    padding-left: 5px;
  }

  @media (max-width: 900px) {
    .MuiFormControl-root-MuiTextField-root input,
    .MuiFormControl-root-MuiTextField-root input::placeholder {
      padding-left: 3px;
    }
  }

  .MuiFormControl-root-MuiTextField-root svg {
    fill: #ffffff;
  }

  .MuiInputBase-root
    .MuiOutlinedInput-root
    .MuiInputBase-colorPrimary
    .MuiInputBase-formControl
    .MuiInputBase-adornedEnd
    .MuiInputBase-root-MuiOutlinedInput-root {
    box-shadow: 2px 2px 2px 0px #95131e66 inset !important;
    box-shadow: -2px -2px 3px 0px #cc162566 inset !important;
    background-color: #ba0c2f !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    padding-right: 0 !important;
  }

  .MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    display: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }