/* Gotham Font - Regular (400) */
@font-face {
  font-family: 'Gotham';
  src: url('./fonts/GothamLight.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
/* Gotham Font - Regular (500) */
@font-face {
  font-family: 'Gotham';
  src: url('./fonts/GothamMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Gotham Font - Bold (700) */
@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

/* Gotham Font - Italic (700) */
@font-face {
  font-family: 'Gotham';
  src: url('./fonts/GothamBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* ITC Caslon 224 Std Font - Medium (500) */
@font-face {
  font-family: 'ITC Caslon 224 Std';
  src: url('./fonts/ITC-Caslon-224-Std-Medium.ttf') format('truetype'); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

/* ITC Caslon 224 Std Font - Bold (700) */
@font-face {
  font-family: 'ITC Caslon 224 Std';
  src: url('./fonts/ITC-Caslon-224-Std-Bold.ttf') format('truetype'); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}